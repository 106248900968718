@import "../../../config/styles/theme.less";

.mapboxgl-ctrl-logo {
  display: none !important;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
  background-image: url(../../../../src/assets/images/map-zoom-in-icon.svg) !important;
  background-repeat: no-repeat;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
  background-image: url(../../../../src/assets/images/map-zoom-out-icon.svg) !important;
  background-repeat: no-repeat;
}

.mapboxgl-ctrl-group {
  border-radius: 8px !important;
  background: var(--Map-Component-Fill, rgba(20, 21, 28, 0.5)) !important;
}
.mapboxgl-ctrl .mapboxgl-ctrl-group {
  border-radius: 8px !important;
  background: rgba(20, 21, 28, 0.5);
  backdrop-filter: blur(5px);
}
.layer-paragraph {
  position: relative;
  z-index: 1;
  .paragraph-content {
    .paragraph-content-container {
      position: relative;
      right: 100px;
      display: flex;
      flex-wrap: wrap;
      gap: 5%;
      color: white;
      margin-left: 2%;
      margin-top: 25px;
      left: 980px;
      .hide-btn {
        background: none;
        border: none;
        color: white !important;
      }
    }
    .layers-main {
      position: absolute;
      left: 980px;
      background-color: rgba(0, 0, 0, 0.4);
      z-index: 100;
      border-radius: 12px;
      color: rgba(134, 140, 162, 1);
      margin-left: 2%;
      width: 100%;
      max-width: 208px;
      .paragraph {
        display: flex;
        justify-content: center;
      }
      .layers-container {
        padding: 5px 10px;
        .tab-content {
          font-size: 12px;
        }
        .ant-tabs-nav {
          margin-bottom: 0;
        }
        .ant-tabs-nav::before {
          display: none;
        }
        .ant-tabs-nav-more {
          color: white;
        }
        .ant-tabs-content {
          color: @--neutral-neutral-30;
          .ant-btn {
            background-color: @--neutral-neutral-90;
            border: none;
            border-radius: 8px;
            width: 36px;
            height: 36px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .drawer-button.active {
            border: 1px solid white;
          }
          .close-btn-drawer {
            background-color: @--neutral-neutral-60;
            border-radius: 50%;
            position: relative;
            bottom: 40px;
            left: 25px;
            padding: 0;
            height: 14px;
            width: 14px;
            .close-button {
              color: white;
            }
          }
          .ant-tabs-tabpane.ant-tabs-tabpane-active {
            background-color: rgba(77, 81, 101, 0.5);
            max-width: 190px;
            border-radius: 0 0 8px 8px;
            padding: 15px;
          }
        }
        .ant-tabs-nav-list {
          .ant-tabs-tab {
            opacity: 0.5;
            width: 46px;
            height: 36px;
            display: flex;
            justify-content: center;
            margin: 0;
          }
          .ant-tabs-ink-bar {
            background: white;
            position: absolute;
            top: 2px;
            border-radius: 12px;
            width: 4px !important;
          }
        }
        .ant-tabs-tab-active.ant-tabs-tab {
          opacity: 1;
          background-color: rgba(77, 81, 101, 0.5);
          border-radius: 8px 8px 0 0;
        }
      }
    }
  }
}
.corner-btn {
  position: relative;
  z-index: 2;
  .service-focus-btn {
    .focus-button {
      position: absolute;
      top: 583px;
      left: 1186px;
    }
    .service-button {
      position: absolute;
      top: 535px;
      left: 1186px;
    }
  }
  .ant-btn {
    background: none;
    border: none;
  }
  .comman-class {
    background-color: @--neutral-neutral-100;
    height: 42px;
    border: none;
    width: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
  }
}

.ant-drawer-content-wrapper .layer-drawer {
  .drawer-main {
    margin-bottom: 15px;
    .drawer-header {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .close-btn {
        .ant-btn {
          border: none;
          color: @--neutral-neutral-30;
        }
      }
    }
  }
  .slider-content {
    .ant-slider-track {
      background-color: @--green-green-40;
    }
    .ant-slider .ant-slider-handle::after {
      box-shadow: 0 0 0 2px @--green-green-40;
    }
    .cloud-content {
      span,
      p {
        color: @--neutral-neutral-60;
      }
      display: flex;
      justify-content: space-between;
    }
  }
  .all-date-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    .date-container {
      border: 1px solid @--neutral-neutral-10;
      border-radius: 8px;
      .date-content {
        padding: 10px;
        display: flex;
        gap: 11px;
        align-items: center;
        p {
          color: @--neutral-neutral-60;
          font-weight: 400;
          font-size: 12px;
        }
        span {
          color: @--neutral-neutral-100;
          font-weight: 500;
        }
        .date-color-container {
          .date-color-content {
            display: flex;
            align-items: center;
            gap: 8px;
          }
          .color-box {
            background-color: @--green-green-40;
            height: 12px;
            width: 12px;
            border-radius: 2px;
          }
        }
        .right-btn {
          position: relative;
          left: 130px;
          .ant-btn {
            border: none;
          }
        }
      }
    }
  }
}

.custom-popup.mapboxgl-popup {
  .mapboxgl-popup-content {
    background-color: @--neutral-neutral-90;
    color: white;
  }
  .mapboxgl-popup-tip {
    border-top-color: @--neutral-neutral-90;
  }
}

.mapboxgl-ctrl-group button+button {
  border-top: none !important;
}

.mapboxgl-ctrl-bottom-right {
  bottom: 5px !important;
  right: 5px !important;
}

@--neutral-neutral-00: #ffffff;@--neutral-neutral-10: rgba(243, 244, 246, 1);@--neutral-neutral-20: rgba(223, 226, 231, 1);@--neutral-neutral-30: rgba(194, 199, 208, 1);@--neutral-neutral-40: rgba(163, 169, 185, 1);@--neutral-neutral-60: rgba(105, 111, 136, 1);@--neutral-neutral-70: rgba(77, 81, 101, 1);@--neutral-neutral-90: rgba(35, 38, 48, 1);@--neutral-neutral-100: rgba(20, 21, 28, 1);@ant-prefix: syngenta-ant;@--green-green-10: rgba(223, 251, 232, 1);@--green-green-40: rgba(25, 160, 75, 1);@--green-green-50: #19a04b;@--green-green-60: rgba(20, 128, 60, 1);@--light-black-85: rgba(0, 0, 0, 0.85);@--light-gray-15: rgba(0, 0, 0, 0.15);@--light-gray-20: rgba(105, 111, 136, 1);@--blue-blue-10: rgba(234, 246, 255, 1);@--blue-blue-20: rgba(198, 230, 255, 1);@--blue-blue-40: rgba(49, 180, 242, 1);@--blue-blue-80: rgba(49, 180, 242, 0.3);@--blue-blue-50: rgba(0, 146, 228, 1);@--blue-blue-70: rgba(0, 113, 205, 1);@--blue-blue-90: rgba(0, 86, 147, 1);@--blue-blue-60: rgba(0, 113, 205, 1);@--red-red-50: #eb4b4b;@--red-red-60: rgba(207, 53, 55, 1);@--box-shadow-grey: #00000026;@--gray-gray-20: #515253;@--font-family: "Noto Sans",sans-serif;