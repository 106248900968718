@import "./config/styles/theme.less";

.navbarLogo {
  margin: 0px 8px 0px 0px !important;
}

.@{ant-prefix}-layout-sider-trigger {
  background-color: @--neutral-neutral-90 !important;
}

.@{ant-prefix}-layout-sider,
.@{ant-prefix}-menu.@{ant-prefix}-menu-dark {
  background-color: @--neutral-neutral-90 !important;
}

.loader {
  border: 4px solid #dfe2e7;
  border-radius: 50%;
  border-top: 4px solid @--neutral-neutral-90;
  width: 36px;
  height: 36px;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}

.main-content{
  height: calc(100vh - 56px);
  overflow-y: auto;
}

.loader-wrapper {
  position: fixed;
  display: flex;
  align-items: center;
  flex-direction: column;
  top: 50%;
  z-index: 9999999;
  left: 50%;
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it is above other elements */
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader-text {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: @--neutral-neutral-00;
  margin-bottom: 10px;
}

.navbarUserArea {
  padding-right: 0px !important;
  margin-top: 7px !important;
}

.syt-antd-popover-inner-content {
  padding: 5px 0px 20px 0px !important;
}

.syngenta-ant-menu-inline-collapsed {
  width: auto !important;
}

.syt-antd-avatar-circle {
  width: 40px;
  height: 50px;
  line-height: 40px;
  font-size: 18px;
  color: rgb(77, 81, 101);
  display: block;
  cursor: pointer;
  background: rgb(243, 244, 246);
  font-weight: 600;
  text-align: center;
}

.triggerCloseButton {
  margin-top: 13px !important;
  margin-right: 13px !important;
}

.triggerExpandButton {
  margin-top: 13px !important;
}

.navbarDivider {
  display: none;
}


@--neutral-neutral-00: #ffffff;@--neutral-neutral-10: rgba(243, 244, 246, 1);@--neutral-neutral-20: rgba(223, 226, 231, 1);@--neutral-neutral-30: rgba(194, 199, 208, 1);@--neutral-neutral-40: rgba(163, 169, 185, 1);@--neutral-neutral-60: rgba(105, 111, 136, 1);@--neutral-neutral-70: rgba(77, 81, 101, 1);@--neutral-neutral-90: rgba(35, 38, 48, 1);@--neutral-neutral-100: rgba(20, 21, 28, 1);@ant-prefix: syngenta-ant;@--green-green-10: rgba(223, 251, 232, 1);@--green-green-40: rgba(25, 160, 75, 1);@--green-green-50: #19a04b;@--green-green-60: rgba(20, 128, 60, 1);@--light-black-85: rgba(0, 0, 0, 0.85);@--light-gray-15: rgba(0, 0, 0, 0.15);@--light-gray-20: rgba(105, 111, 136, 1);@--blue-blue-10: rgba(234, 246, 255, 1);@--blue-blue-20: rgba(198, 230, 255, 1);@--blue-blue-40: rgba(49, 180, 242, 1);@--blue-blue-80: rgba(49, 180, 242, 0.3);@--blue-blue-50: rgba(0, 146, 228, 1);@--blue-blue-70: rgba(0, 113, 205, 1);@--blue-blue-90: rgba(0, 86, 147, 1);@--blue-blue-60: rgba(0, 113, 205, 1);@--red-red-50: #eb4b4b;@--red-red-60: rgba(207, 53, 55, 1);@--box-shadow-grey: #00000026;@--gray-gray-20: #515253;@--font-family: "Noto Sans",sans-serif;