@import "../../../config/styles/theme.less";

.defaultButton:disabled {
    color: #999999 !important;
}

.defaultButton:disabled:hover {
    background-color: #F3F4F6 !important;
}


.org-select-dropdown-main {
    display: flex;
    align-items: center;

    .select-dropdown {
        margin-left: 8px;
        padding: 4px 8px 4px 16px;
        border-radius: 4px;
        background-color: transparent;
        border: 0;
        outline: none;
        cursor: pointer;
        width: max-content;
        text-align: start;

        &:hover {
            background-color: @--neutral-neutral-20;
        }

        h2 {
            color: @--neutral-neutral-60;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            margin-bottom: 0;
        }

        .start-select {
            display: flex;
            align-items: center;
            gap: 12px;

            h4 {
                margin-bottom: 0;
                color: @--neutral-neutral-100;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                white-space: nowrap;
                text-overflow: ellipsis;
                display: block;
                overflow: hidden;
                letter-spacing: -0.105px;
            }

            img {
                width: 11px;
            }
        }
    }
}

.ant-popover.org-select-dropdown {
    max-width: 320px;
    width: 100%;

    .ant-popover-inner {
        button {
            outline: 0;
            border: 0;
            background-color: transparent;
            cursor: pointer;
        }

        padding: 0;

        .ant-popover-title {
            padding: 12px 12px 0 12px;
            font-weight: bold;
            margin-bottom: 0;
        }

        .upper-content {
            padding: 8px 0;
            max-height: 200px; /* Limit height */
            overflow-y: auto; /* Enable scrolling */

            .select-values {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                button {
                    color: @--neutral-neutral-100;
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    padding: 7px 12px;
                    width: 100%;

                    &:hover {
                        background-color: @--neutral-neutral-20;
                    }

                    .splits {
                        border-radius: 8px;
                        background-color: @--neutral-neutral-10;
                        height: 24px;
                        width: 24px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 10px;
                        font-weight: 700;
                    }
                }
            }
        }

        .goto-org {
            padding: 7px 10px;
            border-top: 1px solid @--neutral-neutral-10;

            button {
                color: @--green-green-60;
            }
        }

    }
}
@--neutral-neutral-00: #ffffff;@--neutral-neutral-10: rgba(243, 244, 246, 1);@--neutral-neutral-20: rgba(223, 226, 231, 1);@--neutral-neutral-30: rgba(194, 199, 208, 1);@--neutral-neutral-40: rgba(163, 169, 185, 1);@--neutral-neutral-60: rgba(105, 111, 136, 1);@--neutral-neutral-70: rgba(77, 81, 101, 1);@--neutral-neutral-90: rgba(35, 38, 48, 1);@--neutral-neutral-100: rgba(20, 21, 28, 1);@ant-prefix: syngenta-ant;@--green-green-10: rgba(223, 251, 232, 1);@--green-green-40: rgba(25, 160, 75, 1);@--green-green-50: #19a04b;@--green-green-60: rgba(20, 128, 60, 1);@--light-black-85: rgba(0, 0, 0, 0.85);@--light-gray-15: rgba(0, 0, 0, 0.15);@--light-gray-20: rgba(105, 111, 136, 1);@--blue-blue-10: rgba(234, 246, 255, 1);@--blue-blue-20: rgba(198, 230, 255, 1);@--blue-blue-40: rgba(49, 180, 242, 1);@--blue-blue-80: rgba(49, 180, 242, 0.3);@--blue-blue-50: rgba(0, 146, 228, 1);@--blue-blue-70: rgba(0, 113, 205, 1);@--blue-blue-90: rgba(0, 86, 147, 1);@--blue-blue-60: rgba(0, 113, 205, 1);@--red-red-50: #eb4b4b;@--red-red-60: rgba(207, 53, 55, 1);@--box-shadow-grey: #00000026;@--gray-gray-20: #515253;@--font-family: "Noto Sans",sans-serif;