.map {
    width: 900px;
    height: 600px;
}

/* Need to fix */
.mapboxgl-map {
    position: relative !important;
    width: 100% !important;
    height: 100% !important;
    top: unset !important;
    bottom: unset !important;
}

.mapboxgl-ctrl-group {
    border-radius: 1px;
}

.mapboxgl-ctrl-group button {
    width: 40px;
    height: 40px;
}

.mapboxgl-ctrl-group button:focus {
    box-shadow: none;
}

.mapboxgl-ctrl-group>.mapboxgl-ctrl-icon {
    width: 40px;
    height: 40px;
}

.mapboxgl-ctrl .mapboxgl-ctrl-group {
    border-radius: 8px !important;
}

.mapboxgl-ctrl-group>.mapboxgl-ctrl-icon>button {
    width: 24px !important;
    height: 24px !important;
    border-radius: 8px !important;
}

.mapboxgl-ctrl-compass-arrow {
    margin: 0.1em 2px !important;
}

.hide-bottom-sec-btn {
    display: none !important;
}

.footer-main {
    display: flex;
    width: 100%;
    height: 72px;
    padding: 16px 24px;
    justify-content: flex-end;
    align-items: center;
    flex-shrink: 0;
    gap: 8px;
    background-color: #fff;
    border-top: 1px solid #d9d9d9;

    .save-btn {
        .ant-btn-compact-first-item.ant-btn-lg {
            border-radius: 4px 0 0 4px;
        }
    
        .ant-btn-compact-last-item.ant-btn-lg {
            border-radius: 0 4px 4px 0;
        }
    
        button:hover {
            color: #fff !important;
            border: #14803c;
        }
    }

    .back-button {
        background-color: transparent;
        color: #000 !important;
        border: none !important;
    }

    button {
        font-size: 14px !important;
        background-color: #14803c;
        color: #ffffff;
    }

}
@--neutral-neutral-00: #ffffff;@--neutral-neutral-10: rgba(243, 244, 246, 1);@--neutral-neutral-20: rgba(223, 226, 231, 1);@--neutral-neutral-30: rgba(194, 199, 208, 1);@--neutral-neutral-40: rgba(163, 169, 185, 1);@--neutral-neutral-60: rgba(105, 111, 136, 1);@--neutral-neutral-70: rgba(77, 81, 101, 1);@--neutral-neutral-90: rgba(35, 38, 48, 1);@--neutral-neutral-100: rgba(20, 21, 28, 1);@ant-prefix: syngenta-ant;@--green-green-10: rgba(223, 251, 232, 1);@--green-green-40: rgba(25, 160, 75, 1);@--green-green-50: #19a04b;@--green-green-60: rgba(20, 128, 60, 1);@--light-black-85: rgba(0, 0, 0, 0.85);@--light-gray-15: rgba(0, 0, 0, 0.15);@--light-gray-20: rgba(105, 111, 136, 1);@--blue-blue-10: rgba(234, 246, 255, 1);@--blue-blue-20: rgba(198, 230, 255, 1);@--blue-blue-40: rgba(49, 180, 242, 1);@--blue-blue-80: rgba(49, 180, 242, 0.3);@--blue-blue-50: rgba(0, 146, 228, 1);@--blue-blue-70: rgba(0, 113, 205, 1);@--blue-blue-90: rgba(0, 86, 147, 1);@--blue-blue-60: rgba(0, 113, 205, 1);@--red-red-50: #eb4b4b;@--red-red-60: rgba(207, 53, 55, 1);@--box-shadow-grey: #00000026;@--gray-gray-20: #515253;@--font-family: "Noto Sans",sans-serif;