@import "../../config/styles/theme.less";

.ant-form-item-label {
  label {
    &::before {
      right: 0px;
      top: 0;
      position: absolute;
      color: @--red-red-60 !important;
    }
  }
}

.createProtocol-page {
  height: calc(100vh - 56px);
  display: flex;
  flex-direction: column;

  .createProtocol-header {
    display: flex;
    position: sticky;
    top: 0;
    z-index: 11;
    left: 0;
    background-color: #f0f2f5;
    border: 1px solid @--neutral-neutral-30;

    .breadcrumbs {
      display: flex;
      align-items: center;
      padding: 10px 8px;
      flex-shrink: 0;

      .Left-button-icon {
        box-shadow: none;
        outline: none;
        border: 0;
        cursor: pointer;
        background-color: transparent;
      }

      p {
        margin-bottom: 0;
        font-size: 16px;
        font-weight: 600;
      }
    }

    .stepper-common {
      padding: 10px 36px 10px 72px;
      width: 80%;
      margin: auto;

      .ant-steps-item {
        .ant-steps-item-icon {
          border-color: @--neutral-neutral-40;
          background-color: #fff;
        }
      }

      .ant-steps-item.ant-steps-item-active {
        .ant-steps-item-icon {
          background-color: @--blue-blue-70;
          border-color: transparent;
        }

        .ant-steps-item-title {
          font-weight: 600;

          &:after {
            background-color: @--blue-blue-70;
          }
        }
      }
    }
  }

  .createProtocol-body {
    height: calc(100vh - 175px);
    display: flex;
    overflow-y: auto;
    background-color: white;
    justify-content: center;

    h2 {
      color: #363948;
      font-family: "Noto Sans", sans-serif;
      font-size: 20px;
      font-weight: 600;
      letter-spacing: -0.15px;
      margin: 0;
    }

    .router-outlet {
      height: 100%;
    }

    .rules-container {
      max-width: 824px;
    }
  }

  .bottom-sec-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: sticky;
    bottom: 0;
    right: 0;
    z-index: 0;
    background-color: white;
    width: 100%;
    column-gap: 20px;
    padding: 12px 24px;
    border-top: 1px solid @--neutral-neutral-40;

    button {
      padding: 10px 16px;
      border-radius: 4px;
      font-size: 14px;
      display: flex;
      align-items: center;

      &:focus {
        box-shadow: none;
      }

      &:first-child {
        border: none;
        background-color: transparent;
        gap: 5px;
        box-shadow: none;
      }
    }

    .cancel-btn:hover {
      color: rgba(0, 0, 0, 0.88);
    }

    .split-button {
      display: flex;
      align-items: flex-start;

      .next-btn {
        gap: 8px;
        flex-direction: row;
        border: 1px solid @--green-green-60;
        background-color: @--green-green-60;
        color: @--neutral-neutral-00;

        svg {
          fill: @--neutral-neutral-00;
        }
      }

      .next-btn.active {
        background-color: @--neutral-neutral-10;
        border: 1px solid @--neutral-neutral-20;
        color: @--neutral-neutral-30;

        svg {
          fill: @--neutral-neutral-30;
        }
      }
    }

    .protocol-summary-btn {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .protocol-save-options {
      margin-left: 1px;
      padding: 11px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    .protocol-save-options-icon {
      font-size: 14px !important;
      color: white !important;
    }
  }
}

.trialTypes-component {
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 80px;

  p {
    color: var(--Neutral-neutral-60, #696F88);
    font-family: "Noto Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.105px;
    margin: 0;
  }

  .trial-types-header {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .trialTypes-cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 24px;

    .trialTypes-card-content {
      width: 214px;
    }

    .trialTypes-card {
      position: relative;
      border: 1px solid @--neutral-neutral-30;
      border-radius: 8px;

      .ant-card-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 24px 16px;
        text-align: center;

        .backdrop {
          height: 100%;
          width: 100%;
          background-color: rgba(243, 244, 246, 1);
          opacity: 70%;
          position: absolute;
          top: 0;
          left: 0;
          border-radius: 8px;
        }

        .trial-type-img {
          width: 64px;
          height: 64px;
          margin: auto;
          margin-bottom: 18px;
        }

        .trailTypes-card-heading {
          color: inherit;
          font-family: "Noto Sans", sans-serif;
          font-size: 16px;
          font-style: normal;
          margin-bottom: 8px;
          font-weight: 600;
          letter-spacing: -0.12px;
        }

        .trailtype-card-description {
          color: var(--Neutral-neutral-60, #696F88);
          font-family: "Noto Sans", sans-serif;
          font-size: 12px;
          font-weight: 400;
          letter-spacing: -0.09px;
        }
      }
    }

    .active {
      border-radius: 8px;
      border: 2px solid var(--Blue-blue-50, #0092e4);
      background: var(--Blue-blue-10, #eaf6ff);
      box-shadow: 0px 0px 4px 0px rgba(0, 146, 228, 0.5);
    }
  }
}

.basicInfo-component {
  padding: 24px;
  background-color: white;
  padding-bottom: 0;

  .ant-input-number-handler-wrap {
    opacity: 1;
  }

  .basicInfo-container {
    .basicInfo-form-section {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      .basicinfo-plot {
        width: 50%;
      }

      .ant-form-item-label {
        padding: 0;
        color: var(--Neutral-neutral-100, #14151C);
        font-family: "Noto Sans", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: -0.105px;
      }

      .ant-select-single.ant-select-lg {
        height: 37px;
      }

      .ant-input-number-lg,
      .ant-input-number {
        border-radius: 4px;
      }

      input,
      .ant-select-selector,
      textarea {
        padding: 7px 11px;
        border-radius: 4px;
        font-size: 14px;
      }

      .form-plot-size {
        color: var(--Neutral-neutral-100, #14151C);
        font-family: "Noto Sans", sans-serif;
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 4px;
        letter-spacing: -0.105px;

        .plotsize-info-icon {
          width: 22px;
          height: 22px;
          padding: 4px;
        }
      }

      .basicInfo-input-num {
        width: 100%;
      }

      .ant-form-item-with-help .ant-form-item-explain {
        margin-bottom: 16px;
      }

      .basicInfo-input-num-form-item {
        width: 50%;

        .ant-form-item-explain-error {
          width: 100%;
        }
      }

    }
  }
}

.ant-drawer-body {
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  .objective-drawer-footer {
    display: flex;
    position: absolute;
    bottom: 32px;
    right: 16px;
    column-gap: 8px;

    .objective-drawer-save {
      width: 64px;
      color: @--neutral-neutral-10;
      border-color: @--neutral-neutral-20;

      &:not([disabled]) {
        background-color: @--green-green-60;
        color: #fff;
      }
    }
  }
}

.objectives-component {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .objectives-header {
    display: flex;
    justify-content: space-between;

    .add-objective-btn {
      border-radius: 4px;
      padding: 10px 16px;
      display: flex;
      height: 40px;
      gap: 4px;
      align-items: center;
      color: @--green-green-50;
      border-width: 1px;
      border-color: @--green-green-50;
      &:hover {
        color: @--green-green-50;
        border-color: @--green-green-50;
      }
    }

    .objectives-header-text {
      display: flex;
      flex-direction: column;
      gap: 8px;
      max-width: 70%;

      p {
        margin: 0;
        color: var(--Neutral-neutral-60, #696F88);
        font-family: "Noto Sans";
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.105px;
      }
    }
  }

  .ant-form-item-label {
    padding-bottom: 2px !important;
  }

  .empty-obj-text {
    color: @--neutral-neutral-100;
  }

  .list-container {

    .objective-list-item {
      padding: 14px;
      display: flex;
      justify-content: flex-start;
      border: 1px solid @--neutral-neutral-30;
      border-radius: 8px;
      background-color: #fff;
      margin-bottom: 16px;

      .objective-item-icon {
        padding-right: 8px;
      }

      .objective-item-name {
        flex-shrink: 0;
        padding-right: 8px;
        border-right: 2px solid @--neutral-neutral-20;

        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.75%;
        font-weight: 600;
      }

      .objective-item-target {
        flex-shrink: 0;
        padding-right: 12px;
        margin-left: 12px;

        border-right: 2px solid @--neutral-neutral-20;
      }

      .objective-item-description {
        flex-shrink: 1;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
        padding-right: 12px;
        margin-left: 12px;
      }

      .objective-item-actions {
        border-left: 2px solid @--neutral-neutral-20;
        padding-left: 12px;
        flex-shrink: 0;
        position: absolute;
        right: 10px;
      }
    }

    .objective-item-dragging {
      background-color: rgba(243, 244, 246, 1);
    }
  }
}

.products-nutrients-outer-div {
  display: flex;
  padding-top: 24px;
  flex-direction: column;
  align-items: center;
}

.products-component {
  padding: 24px;
  padding-bottom: 0;

  .ant-tabs-nav {
    margin-bottom: 0;
  }

  .products-seed-component {
    display: flex;
    flex-direction: row;

    .ant-input-number-handler-wrap {
      opacity: 1;
    }

    .d-input-number-handler-wrap {
      opacity: 1;
    }

    .products-left {
      padding: 24px 24px 8px 0;
      width: 65%;
      flex-shrink: 0;

      .product-left-content {
        flex-direction: column;
        display: flex;
        gap: 20px;
        padding-top: 20px;

        .ant-table {
          height: 340px;
          overflow-y: auto;

          .ant-table-cell {
            padding: 9px !important;
          }
        }

        .ant-table-pagination.ant-pagination {
          justify-content: center;

          .ant-pagination-prev {
            margin-left: 0;
          }
        }
      }
    }

    .products-right {
      padding: 24px 0 24px 24px;
      display: flex;
      flex-direction: column;
      gap: 24px;
      border-left: 2px solid @--neutral-neutral-30;
      width: 35%;

      .empty-state-pcontent {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;
        text-align: center;
      }

      .measurement-unit-select {
        width: 100px;
        height: 24px;
        border-radius: 4px;
        padding: 0;
      }

      .prodcuts-right-header {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 4px;

        .remove-products-btn {
          border: 1px solid @--red-red-50;
          color: @--red-red-50;
          padding: 2px 8px 2px 8px;
          border-radius: 4px;
          font-size: 14px;
          line-height: 20px;
          background-color: #fff;
          height: 26px;
          display: flex;
          align-items: center;
          justify-content: center;
          column-gap: 4px;
        }

        .remove-products-btn--disabled {
          border: 1px solid @--neutral-neutral-20;
          color: @--neutral-neutral-30;
          padding: 2px 8px 2px 8px;
          border-radius: 4px;
          font-size: 14px;
          line-height: 20px;
          background-color: @--neutral-neutral-10;
          height: 26px;
          display: flex;
          align-items: center;
          justify-content: center;
          column-gap: 4px;
        }
      }

      .drawer-options {
        font-size: 14px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;

        .drawer-density-measure-unit {
          display: flex;
          flex-direction: column;
          gap: 2px;
        }

        .global-density-range {
          width: 50%;
        }

        .density-switch {
          display: flex;
        }

        .density-switch-all {
          width: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex: 1 0 0;
          border-radius: 4px var(--Number, 0px) var(--Number, 0px) 4px;
          border: var(--Number, 1px) solid var(--Neutral-neutral-30, #C2C7D0);
          background: var(--Neutral-neutral-00, #FFF);
          box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.25) inset;
          padding: 2px 8px;
          color: #14151C;
          text-align: center;
          font-family: "Noto Sans";
          font-size: 14px;
          font-weight: 400;
          letter-spacing: -0.105px;
        }

        .density-switch-individual {
          width: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex: 1 0 0;
          border-radius: var(--Number, 0px) 4px 4px var(--Number, 0px);
          border: var(--Number, 1px) solid var(--Neutral-neutral-30, #C2C7D0);
          background: var(--Neutral-neutral-00, #FFF);
          box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.25) inset;
          padding: 2px 8px;
          color: #14151C;
          text-align: center;
          font-family: "Noto Sans";
          font-size: 14px;
          font-weight: 400;
          letter-spacing: -0.105px;
        }

        .isActive {
          border: 1px solid var(--Neutral-neutral-30, #0071CD);
          background: var(--Blue-blue-60, #0071CD);
          color: #fff;
        }
      }

      .ant-flex {
        flex-wrap: wrap;
      }

      .ant-input-number {
        border-radius: 4px;
      }

      .drawer-newitem {
        padding: 24px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 32px;
        flex-shrink: 0;
      }

      .product-cart-heading {
        display: inline-block;
        padding: 0 5px;
      }

      .product-card {
        box-shadow: 0px 4px 12px 0px #00000026;
        padding: 12px;
        border-radius: 8px;
        margin-bottom: 16px;

        .card-product-range {
          justify-content: space-between;
          display: flex;
          align-items: center;
          gap: 10px;
        }
      }
    }

    .empty-product-state {
      display: flex;
    }

    h3 {
      color: var(--Neutral-neutral-100, #14151C);
      font-family: "Noto Sans", sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      margin: 0;
      letter-spacing: -0.15px;
    }

    p {
      margin: 0;
      color: var(--Neutral-neutral-90, #232630);
      font-family: "Noto Sans", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.105px;
    }
  }

  .products-nutrients-component {
    display: flex;
    width: 446px;
    padding-top: 24px;
    flex-direction: column;
    justify-self: center;
    // align-items: center;
    gap: 24px;

    .extra-bottom-margin {
      margin-bottom: 24px;
    }

    .nutrients-header {
      color: var(--Neutral-neutral-80, var(--Neutral-Neutral-80, #363948));
      font-family: "Noto Sans", sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      /* 140% */
      letter-spacing: -0.15px;
    }

    .nutrient-description {
      color: var(--Neutral-neutral-60, #696F88);
      font-family: "Noto Sans", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      /* 142.857% */
      letter-spacing: -0.105px;
    }

    .nutrient-type {
      height: 40px;
      margin-bottom: 24px;

      .nutrient-type-label {
        color: var(--Neutral-neutral-100, #14151C);
        font-family: "Noto Sans", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        /* 142.857% */
        letter-spacing: -0.105px;

        .nutrient-type-category {
          color: var(--Neutral-neutral-60, #696F88);
          font-family: "Noto Sans", sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          /* 166.667% */
          letter-spacing: -0.09px;
        }
      }

      .nutrient-type-select {
        border-radius: 4px;
        border: 1px solid var(--Neutral-neutral-30, #c2c7d0);
        background: var(--Neutral-neutral-00, #fff);
      }
    }

    .target-app-rates-outer-div {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .target-app-rates {
        display: flex;
        gap: 4px;
        align-items: center;

        .target-app-rates-header {
          color: var(--Neutral-neutral-100, #14151C);
          font-family: "Noto Sans", sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: -0.105px;
        }

        .info-icon {
          width: 18px;
          height: 18px;
          align-items: center;
          margin-bottom: 4px;
          color: #a3a9b9;
        }
      }

      .min-max-rates-inputs {
        display: flex;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        width: 100%;
        justify-items: stretch;

        .input-rate-label {
          width: 100%;

          label {
            color: var(--Neutral-neutral-60, #696F88);
            font-family: "Noto Sans", sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: -0.105px;
            align-items: center;
          }

          .input-number {
            width: 100%;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;
            border-radius: 4px;
            background: var(--Neutral-neutral-00, #fff);
          }
        }
      }
    }

    .rate-intervals {
      display: flex;
      flex-direction: column;
      margin-top: 16px;

      .rate-intervals-header {
        display: flex;
        gap: 4px;

        h3 {
          color: var(--Neutral-neutral-100, #14151C);
          font-family: "Noto Sans", sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: -0.105px;
        }

        .info-icon {
          width: 18px;
          height: 18px;
          align-items: center;
          color: #a3a9b9;
          margin-top: 2px;
        }
      }

      .rate-interval-fields {
        display: flex;
        gap: 24px;

        .increment-steps-label {
          width: 33%;

          label {
            color: var(--Neutral-neutral-60, #696F88);
            font-family: "Noto Sans", sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: -0.105px;
          }

          .input-number {
            width: 95%;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;
            border-radius: 4px;
            background: var(--Neutral-neutral-00, #fff);
          }
        }

        .rate-level-slider {
          width: 67%;

          label {
            color: var(--Neutral-neutral-60, #696F88);
            font-family: "Noto Sans", sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: -0.105px;
          }

          .rate-level-slider-row {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            gap: 16px;
            align-self: stretch;

            .slider {
              display: flex;
              height: 12px;
              justify-content: center;
              align-items: center;
              flex-shrink: 0;
              color: var(--Neutral-neutral-30, #c2c7d0);
            }

            .rate-level-input {
              display: flex;
              height: 32px;
              padding: 4px 10px;
              justify-content: center;
              align-items: center;
              align-self: stretch;
              border-radius: 4px;
              background: var(--Neutral-neutral-00, #fff);
            }

            .disabled-rate-level-input {
              background: #c2c7d0;
              color: #868ca2;
            }
          }
        }
      }
    }

    .disclaimer {
      margin-top: 28px;
      color: var(--Neutral-neutral-100, #14151C);
      font-family: "Noto Sans", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: -0.12px;

      .disclaimer-blue {
        color: var(--Blue-blue-60, #0071CD);
        font-family: "Noto Sans", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: -0.12px;
      }
    }

    .error-message {
      display: flex;
      color: var(--Red-red-50, #EB4B4B);
      font-family: "Noto Sans", sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.09px;
    }

    .syngenta-ant-input-number-out-of-range {
      color: var(--Red-red-50, #eb4b4b);
    }
  }
}

.summary-component {
  padding: 24px;
  padding-right: 46px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .summary-card {
    padding: 16px;
    box-shadow: 0px 4px 12px 0px #00000026 !important;
    width: "90%" !important;
    border-radius: "4px" !important;
    margin: "32px" !important;

    .ant-card-body {
      padding: 0px;
    }

    .ant-card-head {
      padding: 0px;
      border: none;
    }
  }

  .summary-card-static-text {
    color: var(--Neutral-neutral-60, #696f88);
    font-family: "Noto Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.09px;
  }

  .summary-card-static-text-seeds {
    color: var(--Neutral-neutral-70, var(--Neutral-Neutral-70, #4d5165));
    font-family: "Noto Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.105px;
    margin-bottom: -1px;
  }

  .summary-card-dynamic-text {
    color: var(--Neutral-neutral-100, #14151c);
    font-family: "Noto Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: -0.105px;
  }

  .products-header {
    background-color: @--neutral-neutral-10;
    padding: 16px;
  }

  .products-row {
    padding: 16px;
    border: 1px solid @--neutral-neutral-20;
  }
}
@--neutral-neutral-00: #ffffff;@--neutral-neutral-10: rgba(243, 244, 246, 1);@--neutral-neutral-20: rgba(223, 226, 231, 1);@--neutral-neutral-30: rgba(194, 199, 208, 1);@--neutral-neutral-40: rgba(163, 169, 185, 1);@--neutral-neutral-60: rgba(105, 111, 136, 1);@--neutral-neutral-70: rgba(77, 81, 101, 1);@--neutral-neutral-90: rgba(35, 38, 48, 1);@--neutral-neutral-100: rgba(20, 21, 28, 1);@ant-prefix: syngenta-ant;@--green-green-10: rgba(223, 251, 232, 1);@--green-green-40: rgba(25, 160, 75, 1);@--green-green-50: #19a04b;@--green-green-60: rgba(20, 128, 60, 1);@--light-black-85: rgba(0, 0, 0, 0.85);@--light-gray-15: rgba(0, 0, 0, 0.15);@--light-gray-20: rgba(105, 111, 136, 1);@--blue-blue-10: rgba(234, 246, 255, 1);@--blue-blue-20: rgba(198, 230, 255, 1);@--blue-blue-40: rgba(49, 180, 242, 1);@--blue-blue-80: rgba(49, 180, 242, 0.3);@--blue-blue-50: rgba(0, 146, 228, 1);@--blue-blue-70: rgba(0, 113, 205, 1);@--blue-blue-90: rgba(0, 86, 147, 1);@--blue-blue-60: rgba(0, 113, 205, 1);@--red-red-50: #eb4b4b;@--red-red-60: rgba(207, 53, 55, 1);@--box-shadow-grey: #00000026;@--gray-gray-20: #515253;@--font-family: "Noto Sans",sans-serif;