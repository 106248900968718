@import "../../config/styles/theme.less";

.logoutOverlayBlock {
  width: 320px;
  min-height: 120px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.profilePreferences {
 padding: 15px;
}

.profilePreferencesSection {
  padding: 10px 0px;
}

.profileLanguageSection {
 padding: 15px;
 display: flex;
 justify-content: space-between;
}

.profileInfo {
  padding: 15px 15px;
  border-bottom: 1px solid #dfe2e7;
}
.profileInfo h3 {
  font-weight: 600;
  color: #14151c;
}
.profileUserData {
  margin-top: 10px;
  margin-bottom: 10px;
}
.profileUser {
  width: 24px;
  height: 24px;
  font-family: "Noto Sans",sans-serif;
  font-size: 12px;
  text-align: center;
  color: #4d5165;
  border: 1px solid #e8eaed;
  border-radius: 50%;
  margin-right: 10px;
  padding: 3px 3px;
  font-weight: 600;
  background: #f2f4f6;
  float: left;
}
.profileUserData span {
  color: #14151c;
}
.logoutLink {
  padding: 12px 15px 15px 15px;
}
.logoutLink span {
  color: #cf3537;
  cursor: pointer;
}
.logoutLink span:hover {
  color: #ee3f42;
}

.profileInfo .@{ant-prefix}-radio-button-wrapper {
  cursor: pointer;
}
.syt-antd-dropdown-hidden {
  display: none;
}

// top navbar css

.navbar-header {
  .syt-antd-avatar {
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid @--neutral-neutral-10;
    .syt-antd-avatar-string {
      font-size: 14px;
      transform: unset !important;
      font-weight: 600;
    }
  }
  .navbar-right-sec {
    display: flex;
    align-items: center;
    margin-right: 7px;
    button {
      background-color: transparent;
      outline: 0;
      border: 0;
      cursor: pointer;
      padding: 10px;
      svg {
        fill: @--light-gray-20;
        height: 16px;
        width: 16px;
      }
    }
    .divider {
      height: 16px;
      width: 1px;
      background-color: @--neutral-neutral-10;
      margin: 0 12px;
    }
  }
  .select-properties{
    display: flex;
    align-items: center;
    gap: 15px;
  }
}
.ant-popover-inner:has(.del-label){
  .ant-popover-inner-content{
    padding: 0;
  }
}
@--neutral-neutral-00: #ffffff;@--neutral-neutral-10: rgba(243, 244, 246, 1);@--neutral-neutral-20: rgba(223, 226, 231, 1);@--neutral-neutral-30: rgba(194, 199, 208, 1);@--neutral-neutral-40: rgba(163, 169, 185, 1);@--neutral-neutral-60: rgba(105, 111, 136, 1);@--neutral-neutral-70: rgba(77, 81, 101, 1);@--neutral-neutral-90: rgba(35, 38, 48, 1);@--neutral-neutral-100: rgba(20, 21, 28, 1);@ant-prefix: syngenta-ant;@--green-green-10: rgba(223, 251, 232, 1);@--green-green-40: rgba(25, 160, 75, 1);@--green-green-50: #19a04b;@--green-green-60: rgba(20, 128, 60, 1);@--light-black-85: rgba(0, 0, 0, 0.85);@--light-gray-15: rgba(0, 0, 0, 0.15);@--light-gray-20: rgba(105, 111, 136, 1);@--blue-blue-10: rgba(234, 246, 255, 1);@--blue-blue-20: rgba(198, 230, 255, 1);@--blue-blue-40: rgba(49, 180, 242, 1);@--blue-blue-80: rgba(49, 180, 242, 0.3);@--blue-blue-50: rgba(0, 146, 228, 1);@--blue-blue-70: rgba(0, 113, 205, 1);@--blue-blue-90: rgba(0, 86, 147, 1);@--blue-blue-60: rgba(0, 113, 205, 1);@--red-red-50: #eb4b4b;@--red-red-60: rgba(207, 53, 55, 1);@--box-shadow-grey: #00000026;@--gray-gray-20: #515253;@--font-family: "Noto Sans",sans-serif;