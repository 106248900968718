    .monitor-modal {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 775px !important;
        height: auto;
        transform: translate(-50%, -50%);
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
        padding: 0;

        .ant-modal-content {
            padding: 0 !important;
        }

        .ant-modal-header {
            padding: 16px 0;
            text-align: center;
            border-bottom: 1px solid #DFE2E7;
        }

        .ant-modal-body {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            padding: 21px 29px;

            .ant-modal-body-left {
                display: flex;
                position: relative;
                width: 303px;
                margin-right: 16px;
                border-right: 1px solid #DFE2E7;
                padding: 28.6px 36.086px 28.6px 24.603px;
                align-items: center;

                .download-img {
                    z-index: 10;
                }

                .vector1 {
                    position: absolute;
                    top: 45px;
                    z-index: 1;
                }

                .vector2 {
                    position: absolute;
                    z-index: 11;
                    right: 45px;
                    bottom: 37px;
                }

                .vector3 {
                    position: absolute;
                    z-index: 0;
                    top: 44px;
                    left: 18px;
                }
            }

            .ant-modal-body-right {
                display: flex;
                width: calc(100% - 303px);
                flex-direction: column;

                #download-form {
                    padding-top: 7px !important;
                }

                .ant-form-item-label {
                    padding: 0;
                }
            }
        }

        .ant-modal-footer {
            display: none;
        }

        .modal-button {
            display: flex;
            justify-content: flex-end;
            gap: 10px;
            margin-bottom: 20px;
            margin-top: 31px;

            .download-btn {
                background: #14803C;
                color: #fff;
            }

            .download-btn:hover {
                color: #fff;
            }

            .download-btn-disabled {
                background-color: #f3f4f6;
                border: 1px solid #dfe2e7;
                color: #c2c7d0;
            }

            .cancel-btn {
                padding: 10px 16px;
                border-radius: 4px;
                background: #F3F4F6;
            }

            .cancel-btn:hover {
                color: #000;
            }

            button {
                padding: 10px 16px;
                border-radius: 4px;
                font-size: 14px;
                display: flex;
                gap: 4px;
                align-items: center;
            }

            button:hover {
                border: none !important;
            }
        }

    }
@--neutral-neutral-00: #ffffff;@--neutral-neutral-10: rgba(243, 244, 246, 1);@--neutral-neutral-20: rgba(223, 226, 231, 1);@--neutral-neutral-30: rgba(194, 199, 208, 1);@--neutral-neutral-40: rgba(163, 169, 185, 1);@--neutral-neutral-60: rgba(105, 111, 136, 1);@--neutral-neutral-70: rgba(77, 81, 101, 1);@--neutral-neutral-90: rgba(35, 38, 48, 1);@--neutral-neutral-100: rgba(20, 21, 28, 1);@ant-prefix: syngenta-ant;@--green-green-10: rgba(223, 251, 232, 1);@--green-green-40: rgba(25, 160, 75, 1);@--green-green-50: #19a04b;@--green-green-60: rgba(20, 128, 60, 1);@--light-black-85: rgba(0, 0, 0, 0.85);@--light-gray-15: rgba(0, 0, 0, 0.15);@--light-gray-20: rgba(105, 111, 136, 1);@--blue-blue-10: rgba(234, 246, 255, 1);@--blue-blue-20: rgba(198, 230, 255, 1);@--blue-blue-40: rgba(49, 180, 242, 1);@--blue-blue-80: rgba(49, 180, 242, 0.3);@--blue-blue-50: rgba(0, 146, 228, 1);@--blue-blue-70: rgba(0, 113, 205, 1);@--blue-blue-90: rgba(0, 86, 147, 1);@--blue-blue-60: rgba(0, 113, 205, 1);@--red-red-50: #eb4b4b;@--red-red-60: rgba(207, 53, 55, 1);@--box-shadow-grey: #00000026;@--gray-gray-20: #515253;@--font-family: "Noto Sans",sans-serif;