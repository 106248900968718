@import "../../../config/styles/theme.less";

.@{ant-prefix}-card-bordered.org-card {
    width: 100%;
    padding: 16px !important;
    box-shadow: 0px 2px 5px 0px @--box-shadow-grey;
    border: 1px solid @--neutral-neutral-10;
    border-radius: 6px;
    cursor: pointer;
    .@{ant-prefix}-card-body {
      padding: 0px;
    } 
  } 

  .@{ant-prefix}-card-bordered.org-card.disabled  {
    cursor: not-allowed;
    box-shadow: 0px 2px 5px 0px @--box-shadow-grey;
    background: #F3F4F6;
  }


  .@{ant-prefix}-card-bordered:hover {
    box-shadow: 0px 4px 12px 0px @--box-shadow-grey;
  } 

  .@{ant-prefix}-card-bordered.disabled {
    cursor: not-allowed;
    box-shadow: 0px 2px 5px 0px @--box-shadow-grey;
  } 

  .org-card-image {
    margin: 0px auto;
    width: 48px;
    height: 48px;
    border-radius: 50px;
    border: 1px solid #DFE2E7;
    background: linear-gradient(0deg, #F3F4F6, #F3F4F6),
linear-gradient(0deg, #DFE2E7, #DFE2E7);
  }
  

  .org-card-text {
    font-family: @--font-family;
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.0075em;
    text-align: center;
    color: @--blue-blue-50;
    margin-top: 15px;
  }

  .org-card-initials {
    padding: 10px;
    font-family: @--font-family;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: -0.0075em;
    text-align: center;
    border-radius: 50px;
    margin: 0px auto;
    width: 48px;
    height: 48px;

    border: 1px solid #DFE2E7;
    background: linear-gradient(0deg, #F3F4F6, #F3F4F6),
linear-gradient(0deg, #DFE2E7, #DFE2E7);
  }
  .org-card-image img {
    height: 100%;
    width: 100%;
    border-radius: 32px;
  }

@--neutral-neutral-00: #ffffff;@--neutral-neutral-10: rgba(243, 244, 246, 1);@--neutral-neutral-20: rgba(223, 226, 231, 1);@--neutral-neutral-30: rgba(194, 199, 208, 1);@--neutral-neutral-40: rgba(163, 169, 185, 1);@--neutral-neutral-60: rgba(105, 111, 136, 1);@--neutral-neutral-70: rgba(77, 81, 101, 1);@--neutral-neutral-90: rgba(35, 38, 48, 1);@--neutral-neutral-100: rgba(20, 21, 28, 1);@ant-prefix: syngenta-ant;@--green-green-10: rgba(223, 251, 232, 1);@--green-green-40: rgba(25, 160, 75, 1);@--green-green-50: #19a04b;@--green-green-60: rgba(20, 128, 60, 1);@--light-black-85: rgba(0, 0, 0, 0.85);@--light-gray-15: rgba(0, 0, 0, 0.15);@--light-gray-20: rgba(105, 111, 136, 1);@--blue-blue-10: rgba(234, 246, 255, 1);@--blue-blue-20: rgba(198, 230, 255, 1);@--blue-blue-40: rgba(49, 180, 242, 1);@--blue-blue-80: rgba(49, 180, 242, 0.3);@--blue-blue-50: rgba(0, 146, 228, 1);@--blue-blue-70: rgba(0, 113, 205, 1);@--blue-blue-90: rgba(0, 86, 147, 1);@--blue-blue-60: rgba(0, 113, 205, 1);@--red-red-50: #eb4b4b;@--red-red-60: rgba(207, 53, 55, 1);@--box-shadow-grey: #00000026;@--gray-gray-20: #515253;@--font-family: "Noto Sans",sans-serif;