@import "../../../config/styles/theme.less";

.@{ant-prefix}-card-bordered.farm-card {
  width: 980px;
  height: 84px;
  border-radius: 8px;
  border: 1px solid #f3f4f6;
  padding: 15px !important;
  box-shadow: 0px 2px 8px 0px @--box-shadow-grey;
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(0deg, #f3f4f6, #f3f4f6);
  .@{ant-prefix}-card-body {
    padding: 0px;
    display: inline-flex;
  }
}

.farm-text {
  font-family: "Noto Sans",sans-serif ;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.0075em;
  text-align: left;
  padding: 15px;
}

.farm-ticker {
  width: 4px;
  height: 40px;
  border-radius: 0px 4px 4px 0px;
  perspective: 1px;
  background: #14803c;
  position: absolute;
  top: 20px;
  left: 0px;
}

.farm-card {
  .syngenta-ant-card-body {

    &:before,
    &:after {
      content: unset;
    }

    .first-field {
      width: 40%;
      display: flex;
      align-items: center;
    }

    .farm-text {
      color: @--neutral-neutral-100;
      padding: 5px;
      display: flex;

      align-items: center;
      gap: 16px;
    }

    .second-section {
      width: 25%;
      gap: 4px;
      display: flex;
      align-items: center;

      h3 {
        margin-bottom: 0;
        color: #14151c;
        font-size: 14px;
        font-weight: 400;
      }
    }

    .third-section {
      width: 27%;
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 14px;
      font-weight: 400;

      img {
        height: 14px;
        width: 14px;
      }
    }

    .last-elem {
      width: 8%;
      padding: 4px 28px;
      border-left: 1px solid @--neutral-neutral-30;
      display: flex;
      gap: 30px;
      align-items: center;

      .color-box {
        display: none;

        img {
          height: 20px;
          width: 20px;
        }
      }

      .edit-btn {
        margin-left: auto;
        background-color: unset;
        border: 0;
        color: @--blue-blue-50;
        padding: 0;
        cursor: pointer;
      }
    }
  }
}

.farm-star {
  width: 40px;
  height: 40px;
  padding: 7px;
  border-radius: 4px;
}

.farm-arrow {
  width: 20px;
  height: 20px;
  padding: 15px 0px;
  border-radius: 4px;
}

.@{ant-prefix}-card-bordered:hover {
  box-shadow: 0px 4px 12px 0px @--box-shadow-grey;
}

.@{ant-prefix}-card-bordered.disabled {
  cursor: not-allowed;
  box-shadow: 0px 2px 8px 0px @--box-shadow-grey;
}
@--neutral-neutral-00: #ffffff;@--neutral-neutral-10: rgba(243, 244, 246, 1);@--neutral-neutral-20: rgba(223, 226, 231, 1);@--neutral-neutral-30: rgba(194, 199, 208, 1);@--neutral-neutral-40: rgba(163, 169, 185, 1);@--neutral-neutral-60: rgba(105, 111, 136, 1);@--neutral-neutral-70: rgba(77, 81, 101, 1);@--neutral-neutral-90: rgba(35, 38, 48, 1);@--neutral-neutral-100: rgba(20, 21, 28, 1);@ant-prefix: syngenta-ant;@--green-green-10: rgba(223, 251, 232, 1);@--green-green-40: rgba(25, 160, 75, 1);@--green-green-50: #19a04b;@--green-green-60: rgba(20, 128, 60, 1);@--light-black-85: rgba(0, 0, 0, 0.85);@--light-gray-15: rgba(0, 0, 0, 0.15);@--light-gray-20: rgba(105, 111, 136, 1);@--blue-blue-10: rgba(234, 246, 255, 1);@--blue-blue-20: rgba(198, 230, 255, 1);@--blue-blue-40: rgba(49, 180, 242, 1);@--blue-blue-80: rgba(49, 180, 242, 0.3);@--blue-blue-50: rgba(0, 146, 228, 1);@--blue-blue-70: rgba(0, 113, 205, 1);@--blue-blue-90: rgba(0, 86, 147, 1);@--blue-blue-60: rgba(0, 113, 205, 1);@--red-red-50: #eb4b4b;@--red-red-60: rgba(207, 53, 55, 1);@--box-shadow-grey: #00000026;@--gray-gray-20: #515253;@--font-family: "Noto Sans",sans-serif;