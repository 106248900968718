@import "../../../config//styles//theme.less";

.standard-seedrate-main{
  // display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Neutral-Neutral-30, #C2C7D0);

  margin: 16px;

  .seedrate-header{
    display: flex;
    gap: 4px;
    .seedrate-heading{
      color: var(--Neutral-neutral-100, var(--Neutral-Neutral-100, #14151C));
      font-family: "Noto Sans",sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 150% */
      letter-spacing: -0.12px;
    }

    .seedrate-info-icon{
      display: flex;
      width: 22px;
      height: 22px;
      padding: 4px;
      align-items: center;
      gap: 10px;
      margin-top: 2px;
    }

  }
  .seedrate-selections {
    display: flex;
    flex-direction: row  !important;
    width: 100%;
    gap: 8px;
    margin-bottom: 25px;
    .select-product{
      width: 60%;
      height: 50px;
      color: var(--Neutral-neutral-100, var(--Neutral-Neutral-100, #14151C));
      font-family: "Noto Sans",sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      letter-spacing: -0.105px;
      .error-message {
        color: @--red-red-60;
      }
      .selected-product{
        color: var(--Neutral-neutral-60, #696F88);      
      }
      .ant-select-selector {
        background: #f3f4f6;
        border-radius: 3px;
      }
    }
    .input-seedrate{
      height: 50px;
      width: 40%;
      color: var(--Neutral-neutral-100, var(--Neutral-Neutral-100, #14151C));
      font-family: "Noto Sans",sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      letter-spacing: -0.105px;
      .error-message {
        color: @--red-red-60;
      }
      .input-number{
        width: 100%;
        color: var(--Neutral-neutral-60, #696F88);
        border-radius: 4px;
        border: 1px solid var(--Neutral-Neutral-30, #C2C7D0);
        background: var(--Neutral-neutral-10, #F3F4F6);
      }
    }    

  }
}

@--neutral-neutral-00: #ffffff;@--neutral-neutral-10: rgba(243, 244, 246, 1);@--neutral-neutral-20: rgba(223, 226, 231, 1);@--neutral-neutral-30: rgba(194, 199, 208, 1);@--neutral-neutral-40: rgba(163, 169, 185, 1);@--neutral-neutral-60: rgba(105, 111, 136, 1);@--neutral-neutral-70: rgba(77, 81, 101, 1);@--neutral-neutral-90: rgba(35, 38, 48, 1);@--neutral-neutral-100: rgba(20, 21, 28, 1);@ant-prefix: syngenta-ant;@--green-green-10: rgba(223, 251, 232, 1);@--green-green-40: rgba(25, 160, 75, 1);@--green-green-50: #19a04b;@--green-green-60: rgba(20, 128, 60, 1);@--light-black-85: rgba(0, 0, 0, 0.85);@--light-gray-15: rgba(0, 0, 0, 0.15);@--light-gray-20: rgba(105, 111, 136, 1);@--blue-blue-10: rgba(234, 246, 255, 1);@--blue-blue-20: rgba(198, 230, 255, 1);@--blue-blue-40: rgba(49, 180, 242, 1);@--blue-blue-80: rgba(49, 180, 242, 0.3);@--blue-blue-50: rgba(0, 146, 228, 1);@--blue-blue-70: rgba(0, 113, 205, 1);@--blue-blue-90: rgba(0, 86, 147, 1);@--blue-blue-60: rgba(0, 113, 205, 1);@--red-red-50: #eb4b4b;@--red-red-60: rgba(207, 53, 55, 1);@--box-shadow-grey: #00000026;@--gray-gray-20: #515253;@--font-family: "Noto Sans",sans-serif;