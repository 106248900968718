@import "../../../config/styles/theme.less";

.trialing-plot-table-container {
  table {
    height: fit-content;
    tr {
      height: 100%;
      th {
        padding: 5px 0px !important;
      }
    }
  }

  .plot-header-cell {
    padding-right: 10px;
  }

  .ant-table-thead {
    .ant-table-cell {
      background: #f3f4f6;
    }
  }
  .ant-table-tbody {
    .ant-table-cell {
      padding: 0;
      .error-message {
        color: @--red-red-60;
      }
      .ant-select-selector {
        background: #f3f4f6;
        border-radius: 3px;
      }
      .input-number {
        border: 1px solid #c2c7d0;
        input {
          background: #f3f4f6;
        }
        .syngenta-ant-input-number-handler {
          background: #f3f4f6;
        }
      }
    }
    .plottable-seelct-product {
      width: 90%;
    }
  }

  .plot-cell-color-bar-container {
    width: 6px;
    background: rgb(0, 86, 147);
    min-height: 64px;
    display: flex;
    height: 100%;
    margin-right: 42px;
    .ant-checkbox-wrapper {
      margin-left: 16px;
    }
  }
}

@--neutral-neutral-00: #ffffff;@--neutral-neutral-10: rgba(243, 244, 246, 1);@--neutral-neutral-20: rgba(223, 226, 231, 1);@--neutral-neutral-30: rgba(194, 199, 208, 1);@--neutral-neutral-40: rgba(163, 169, 185, 1);@--neutral-neutral-60: rgba(105, 111, 136, 1);@--neutral-neutral-70: rgba(77, 81, 101, 1);@--neutral-neutral-90: rgba(35, 38, 48, 1);@--neutral-neutral-100: rgba(20, 21, 28, 1);@ant-prefix: syngenta-ant;@--green-green-10: rgba(223, 251, 232, 1);@--green-green-40: rgba(25, 160, 75, 1);@--green-green-50: #19a04b;@--green-green-60: rgba(20, 128, 60, 1);@--light-black-85: rgba(0, 0, 0, 0.85);@--light-gray-15: rgba(0, 0, 0, 0.15);@--light-gray-20: rgba(105, 111, 136, 1);@--blue-blue-10: rgba(234, 246, 255, 1);@--blue-blue-20: rgba(198, 230, 255, 1);@--blue-blue-40: rgba(49, 180, 242, 1);@--blue-blue-80: rgba(49, 180, 242, 0.3);@--blue-blue-50: rgba(0, 146, 228, 1);@--blue-blue-70: rgba(0, 113, 205, 1);@--blue-blue-90: rgba(0, 86, 147, 1);@--blue-blue-60: rgba(0, 113, 205, 1);@--red-red-50: #eb4b4b;@--red-red-60: rgba(207, 53, 55, 1);@--box-shadow-grey: #00000026;@--gray-gray-20: #515253;@--font-family: "Noto Sans",sans-serif;