@import "../../../config/styles/theme.less";

.radio-card-main {
    display: flex;
    align-items: center;
    gap: 24px;
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #DFE2E7;
    padding: 10px 16px;
    transition: .3s;
    position: relative;

    &:hover {
        transition: .3s;
        border: 2px solid #0092E4;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
    }

    .radio-div {
        opacity: 0;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    .radio-btn {
        .syt-antd-radio-inner {
            display: none;
        }

        input {
            height: 16px;
            width: 16px;
        }
    }

    .img-section {
        width: 32px;
        height: 32px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .details {

        h3,
        p {
            margin-bottom: 0;
            font-size: 14px;
            line-height: 20px;
        }

        h3 {
            color: @--neutral-neutral-100;
        }

        p {
            color: @--neutral-neutral-60;
            font-weight: 400;
            font-size: 12px;
        }
    }

    .syt-antd-radio {
        display: flex;
    }
}

.selected-field {
    border: 2px solid #0092E4;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
 }
@--neutral-neutral-00: #ffffff;@--neutral-neutral-10: rgba(243, 244, 246, 1);@--neutral-neutral-20: rgba(223, 226, 231, 1);@--neutral-neutral-30: rgba(194, 199, 208, 1);@--neutral-neutral-40: rgba(163, 169, 185, 1);@--neutral-neutral-60: rgba(105, 111, 136, 1);@--neutral-neutral-70: rgba(77, 81, 101, 1);@--neutral-neutral-90: rgba(35, 38, 48, 1);@--neutral-neutral-100: rgba(20, 21, 28, 1);@ant-prefix: syngenta-ant;@--green-green-10: rgba(223, 251, 232, 1);@--green-green-40: rgba(25, 160, 75, 1);@--green-green-50: #19a04b;@--green-green-60: rgba(20, 128, 60, 1);@--light-black-85: rgba(0, 0, 0, 0.85);@--light-gray-15: rgba(0, 0, 0, 0.15);@--light-gray-20: rgba(105, 111, 136, 1);@--blue-blue-10: rgba(234, 246, 255, 1);@--blue-blue-20: rgba(198, 230, 255, 1);@--blue-blue-40: rgba(49, 180, 242, 1);@--blue-blue-80: rgba(49, 180, 242, 0.3);@--blue-blue-50: rgba(0, 146, 228, 1);@--blue-blue-70: rgba(0, 113, 205, 1);@--blue-blue-90: rgba(0, 86, 147, 1);@--blue-blue-60: rgba(0, 113, 205, 1);@--red-red-50: #eb4b4b;@--red-red-60: rgba(207, 53, 55, 1);@--box-shadow-grey: #00000026;@--gray-gray-20: #515253;@--font-family: "Noto Sans",sans-serif;