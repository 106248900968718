@import "../../../config/styles/theme.less";

.plot-card-main {
  border-bottom: 1px solid @--neutral-neutral-20;

  button {
    &:focus {
      box-shadow: none;
    }
  }

  .head-sec {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 12px 0;

    .title-sec {
      display: flex;
      align-items: center;
      gap: 10px;

      .card-title {
        font-size: 14px;
        font-weight: 600;
        color: @--neutral-neutral-100;
        margin-bottom: 0;
      }

      .card-description {
        font-size: 14px;
        color: @--neutral-neutral-70;
        letter-spacing: -0.75%;
        line-height: 20px;
        margin-bottom: 0;
      }
    }

    .plus-btn {
      background-color: transparent;
      border: 0;
      padding: 0;
      outline: none;

      button {
        height: 40px;
        width: 40px;
        border-radius: 50px;
        border: 1px solid @--neutral-neutral-30;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        color: @--neutral-neutral-60;
        background-color: transparent;
        cursor: pointer;
      }
    }

    .plus-btn.disabled {
      button {
        background-color: @--neutral-neutral-20;
        border: 1px solid @--neutral-neutral-10;
        color: @--neutral-neutral-30;
        cursor: default;
      }
    }
  }

  .border-btm {
    border-bottom: 1px solid @--neutral-neutral-20;
  }

  .abgrid-sec {
    margin-left: 32px;
  }

  .open-sec {
    padding: 14px 0;
    gap: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid @--neutral-neutral-10;


    .open-sec-title {
      font-size: 14px;
      font-weight: 600;
      width: 100%;
      color: @--neutral-neutral-100;
      margin-bottom: 0;

      span {
        font-weight: 400;
      }

      .open-sec-title-circle {
        display: flex;
        align-items: center;
      }

      .ab-color-circle {
        border-radius: 50%;
        height: 15px;
        width: 17px;
      }

      .ab-blue {
        background-color: #0092E4;
      }

      .ab-orange {
        background-color: #EE5B3A;
      }

      .ab-purple {
        background-color: #9664F0;
      }

      .ab-pink {
        background-color: #E85D78;
      }

      .ab-teal {
        background-color: #2B9C92;
      }

      .ab-yellow {
        background-color: #C17E19;
      }
    }

    .action-sec {
      display: flex;
      align-items: center;
      gap: 12px;

      button {
        border-radius: 4px;
        outline: none;
        cursor: pointer;
        border: 0;
        background-color: transparent;
      }

      .eye-edit {
        display: flex;
        align-items: center;
        gap: 14px;
        padding-right: 10px;
        border-right: 1px solid @--neutral-neutral-40;

        button {
          padding: 0;

          svg {
            font-size: 16px;
            color: @--neutral-neutral-40;
          }
        }
      }

      .del-right {
        display: flex;
        align-items: center;
        gap: 10px;

        button {
          height: 30px;
          width: 30px;

          svg {
            color: white;
          }
        }

        .del-btn {
          background-color: @--red-red-60;
        }

        .right-btn {
          background-color: @--blue-blue-70;
        }

        .disabled-btn {
          background-color: @--blue-blue-90 ;
        }
      }
    }
  }

  .open-sec.replicant {
    padding-left: 25px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      bottom: 50%;
      transform: translateY(-10%);
      left: 5px;
      height: 35px;
      width: 3px;
      border-left: 2px dashed @--neutral-neutral-30;
    }

    &:after {
      content: "";
      position: absolute;
      bottom: 52%;
      transform: translateY(-23%);
      left: 5px;
      height: 3px;
      width: 15px;
      border-bottom: 2px dashed @--neutral-neutral-30;
    }
  }
}

.popover-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1000;
  opacity: 0;
}

.ant-notification-notice-wrapper:has(.delete-plot-notification) {
  overflow: hidden;

  .delete-plot-notification {
    padding: 16px !important;
    border-left: 4px solid @--green-green-40;

    .ant-notification-notice-message {
      margin-bottom: 0 !important;

      .title-sec {
        display: flex;
        align-items: center;
        gap: 16px;
        color: @--neutral-neutral-100 !important;
        font-size: 16px !important;
        font-weight: 600 !important;

        svg {
          color: @--green-green-40;
          font-size: 24px;
        }
      }
    }

    .ant-notification-notice-close-x {
      display: none;
    }
  }
}

.ant-popover:has(.buffer-zone-popup) {
  .ant-popover-inner {
    max-width: 308px;
    padding: 16px;

    .buffer-zone-popup {
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none;
        border: none;
        cursor: pointer;

        &:focus {
          box-shadow: none;
        }
      }

      .desc {
        font-size: 14px;
        color: @--neutral-neutral-70;
        font-weight: 400;
      }

      .zone-input-sec {
        >div {
          margin-bottom: 10px;
        }

        label {
          font-size: 14px;
          font-weight: 400;
        }

        .input-main {
          display: flex;
          align-items: center;
          gap: 8px;

          button {
            background-color: @--neutral-neutral-10;
            border-radius: 4px;
            height: 40px;
            min-width: 34px;
            color: @--light-gray-20;
            font-size: 20px;
          }

          .ant-input-number {
            width: 100%;
          }

          input {
            height: 40px;
          }
        }
        p {
          span{
            color: var(--Blue-blue-50, #0092E4);
            font-style: italic;
          }
        }
      }

      .apply-btn {
        width: 100%;
        margin-top: 24px;
        border-radius: 4px;
        background-color: @--blue-blue-60;
        padding: 10px 16px;
        color: white;
      }

      .zone-input-sec:has(.grid-input) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;

        .ant-input-number-input-wrap,
        input,
        .input-main .ant-input-number {
          width: 100%;
        }

        .ant-input-number-handler-wrap {
          opacity: 1;
        }
      }

      .zone-input-sec.second-field {
        margin-top: 16px;
      }

      .apply-btn.apply-trial-btn {
        background-color: @--green-green-60;
      }

      .apply-btn.apply-trial-btn.disable {
        background-color: @--neutral-neutral-10;
        color: @--neutral-neutral-30;
        cursor: not-allowed;
      }

      .box-plot {
        margin: 24px 0;
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        gap: 8px;
        position: relative;
        transition: all 0.3s;

        .boxes {
          background-color: @--neutral-neutral-10;
          border: 1px solid @--neutral-neutral-40;
          height: 30px;
          transition: all 0.3s;
        }

        .boxes.active {
          background-color: @--blue-blue-20;
          border: 2px solid @--blue-blue-40;
          transition: all 0.3s;
        }

        .edit-layer {
          position: absolute;
          top: 50%;
          left: 50%;
          height: calc(100% + 10px);
          width: calc(100% + 10px);
          background-color: #80808063;
          display: flex;
          justify-content: center;
          align-items: center;
          transform: translate(-50%, -50%);
          border-radius: 4px;

          button {
            border-radius: 4px;
            font-weight: 500;
            color: gray;
            background-color: white;
            gap: 5px;

            &:hover {
              background-color: white;
            }
          }
        }
      }

      .edit-btns-popover {
        display: flex;
        gap: 12px;
        margin-top: 24px;

        button {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px 5px;
          border-radius: 4px;
        }

        button.apply-btn-edit {
          background-color: @--blue-blue-70;
          color: @--neutral-neutral-00;
        }
      }
    }
  }
}

.ant-popover {
  .popover-title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      background-color: transparent;
      outline: 0;
      border: 0;
      cursor: pointer;
    }
  }
}
@--neutral-neutral-00: #ffffff;@--neutral-neutral-10: rgba(243, 244, 246, 1);@--neutral-neutral-20: rgba(223, 226, 231, 1);@--neutral-neutral-30: rgba(194, 199, 208, 1);@--neutral-neutral-40: rgba(163, 169, 185, 1);@--neutral-neutral-60: rgba(105, 111, 136, 1);@--neutral-neutral-70: rgba(77, 81, 101, 1);@--neutral-neutral-90: rgba(35, 38, 48, 1);@--neutral-neutral-100: rgba(20, 21, 28, 1);@ant-prefix: syngenta-ant;@--green-green-10: rgba(223, 251, 232, 1);@--green-green-40: rgba(25, 160, 75, 1);@--green-green-50: #19a04b;@--green-green-60: rgba(20, 128, 60, 1);@--light-black-85: rgba(0, 0, 0, 0.85);@--light-gray-15: rgba(0, 0, 0, 0.15);@--light-gray-20: rgba(105, 111, 136, 1);@--blue-blue-10: rgba(234, 246, 255, 1);@--blue-blue-20: rgba(198, 230, 255, 1);@--blue-blue-40: rgba(49, 180, 242, 1);@--blue-blue-80: rgba(49, 180, 242, 0.3);@--blue-blue-50: rgba(0, 146, 228, 1);@--blue-blue-70: rgba(0, 113, 205, 1);@--blue-blue-90: rgba(0, 86, 147, 1);@--blue-blue-60: rgba(0, 113, 205, 1);@--red-red-50: #eb4b4b;@--red-red-60: rgba(207, 53, 55, 1);@--box-shadow-grey: #00000026;@--gray-gray-20: #515253;@--font-family: "Noto Sans",sans-serif;